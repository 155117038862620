<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-body">
    <b>Sie werden zu unserem Softwareanbieter Firma medisoftware
      weitergeleitet</b><br />
      Informationen unter: 
      <a href="https://www.medisoftware.de/html/kontakt/impressum/" target="_blank">Medisoftware/datenschutz</a> 
  </div>
  <div class="modal-footer">
    <button class="btn btn-light" (click)="c('cancel')">Zurück</button>
    <button class="btn btn-primary" (click)="c('OK')">Einverstanden</button>
  </div>
</ng-template>

<button class="btn btn-lg btn-primary"  style="margin: 10px;" (click)="open(content)">Buchung</button>
