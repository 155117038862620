import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-extern-link',
  templateUrl: './modal-extern-link.component.html',
  styleUrls: ['./modal-extern-link.component.css']
})
export class ModalExternLinkComponent implements OnInit {
  constructor( private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  open(content): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if (result === 'OK') {
        window.open('https://order.medidate.org/?pid=290bf8ff-0f2a-4d0c-b561-7021a342f760&ptok=556d506f714639675237516c44776e4b332b50323741427471726c4c44647351414b344e586358624f3468304c3747737141454a7a556577467639574c6c63764c4150474c6362332b6b7a65306571693577317055416957536648356d4969514d524f363168747037795731544468364176526d4254324a75536c6c6c734d305a58754b6d6d6868643063794949547134795067576d586448643547725747356b2b554e354f6c325565553d', '_blank');
      }
    });
  }

}
