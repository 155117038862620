<div class="container">
  <div class="row" style="background-color: white;">
    <div class="col">
      <h1>Nachfolgende Leistungen biete ich Ihnen:</h1>
      <p>Allgemeine hausärztliche Versorgung</p>
      <p>Gesundheitsuntersuchungen</p>
      <p>Hautkrebsscreening</p>
      <p>Sportmedizinische Untersuchungen</p>
      <p>Tauchtauglichkeitsuntersuchungen</p>
      <p>Psychosomatische Grundversorgung</p>
      <p>Behandlungsprogramm für chronisch Erkrankte (DMP) bei:</p>
      <p>
        Diabetes Mellitus,koronare Herzerkrankung(KHK),Asthma bronchiale und
        COPD
      </p>
      <p>Impfungen</p>
      <p>Reisemedizinische Beratung</p>
      <p>Lungenfunktionstestung</p>
      <p>Belastungs-EKG</p>
      <p>Magnetfeldtherapie</p>
    </div>
  </div>
  <div class="row text-center" style="background-color: white;">
    <div class="col" style="margin: 20px;">
      <img src="assets/images/ekg.png"/>
    </div>
    <div class="col" style="margin: 20px;">
      <img src="assets/images/sono.png"/>
    </div>
    <div class="col" style="margin: 20px;">
      <img src="assets/images/labor.png"/>
    </div>
  </div>
</div>
