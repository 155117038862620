<nav class="navbar navbar-expand-lg navbar-light bg-light mb-3">
  <img src="assets/images/a3.png" style="height: 30px; margin-right: 10px" />
  <a class="navbar-brand" [routerLink]="'/'">Allgemeinpraxis Dr. med. Hamann</a>

  <!-- Step 3: Toggle the value of the property when the toggler button is clicked. -->
  <button
    class="navbar-toggler"
    type="button"
    (click)="isMenuCollapsed = !isMenuCollapsed"
  >
    &#9776;
  </button>

  <!-- Step 2: Add the ngbCollapse directive to the element below. -->
  <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
    <ul class="navbar-nav">
      <li class="nav-item" routerLinkActive>
        <!-- Step 4: Close the menu when a link is clicked. -->
        <a
          class="nav-link"
          [routerLink]="'home'"
          (click)="isMenuCollapsed = true"
          >Home</a
        >
      </li>
      <li class="nav-item" routerLinkActive>
        <a
          class="nav-link"
          [routerLink]="'leistung'"
          (click)="isMenuCollapsed = true"
          >Leistungen</a
        >
      </li>
      <li class="nav-item" routerLinkActive>
        <a
          class="nav-link"
          [routerLink]="'datenschutz'"
          (click)="isMenuCollapsed = true"
          >Datenschutz</a
        >
      </li>
      <li class="nav-item" routerLinkActive>
        <a
          class="nav-link"
          [routerLink]="'impressum'"
          (click)="isMenuCollapsed = true"
          >Impressum</a
        >
      </li>
    </ul>
  </div>
</nav>
