<div class="container" >
  <div class="row" style="background-color: white;">
    <div class="col-md-auto">
      <img src="assets/images/hamann.png" />
    </div>
      <div class="col-md-auto" style="margin-top: 20px;">  
        <b>Dr. med. Matthias Hamann</b> <br />
        Facharzt für Allgemeinmedizin <br />
        Sportmedizin<br />
      </div>
  </div>
  <div class="row" style="background-color: white; margin-top: 1px;" >  
    <div class="col" sytel="width: 130px;">
      <div  style="float: left; margin-top: 10px; margin-left: 10px;">
      <p>Heußweg 35</p>
      <P>20255 Hamburg</P>
      <p>Tel. 040 491 00 18</p>
      <p>Tel. 040 491 00 19</p>
      <p>Fax. 040 491 52 88</p>
    </div>
    <div style="float: right; margin-top: 10px;">
      <img src="assets/images/eingang.png"/>
    </div>
    </div>
    <div class="col" sytel="width: 130px;">
      <div  style="float: left; margin-top: 10px; margin-left: 10px;">
      <p><b>Praxisöffnungszeiten:</b></p>
      <p>Mo: 8.00 - 16.00 Uhr</p>
      <p>Di: 8.00 - 16.00 Uhr</p>
      <p>Mi: 8.00 - 13.00 Uhr</p>
      <p>Do: 8.00 - 16.00 Uhr</p>
      <p>Fr: 8.00 - 12.00 Uhr</p>
      <p>Sa: 9.00 - 12.00 Uhr (nach Vereinbarung)</p>
      </div>
      <div style="float: right; margin-top: 10px;">
        <img src="assets/images/empfang.png" style="width: 250px;"/>
      </div>
    </div>
  </div>
  <div class="row" style="background-color: white; margin-top: 1px;">
    <div class="col-md-auto" style="margin-top: 20px;">
        <b>Um einen Termin online zu buchen, ein Rezept oder eine Überweisung zu bestellen klicken Sie bitte auf dem Button Buchung</b>
    </div>
        <div class="col text-center" style="align-items: center; height: 40vh;">
          <app-modal-extern-link></app-modal-extern-link>
        </div>
  </div>
</div>
